import axios from '@/common/js/request'
export function selectTaskName(params){ // 工单管理安检任务名称下拉
    const selectTaskName = axios({
        url: '/checkData/selectTaskName',
        method: 'get',
        params
    })
    return selectTaskName
}
export function selectDataList(params){ // 工单管理列表
    const selectDataList = axios({
        url: '/checkData/selectDataList',
        method: 'get',
        params
    })
    return selectDataList
}
export function selectUserName(params){ // 工单管理安检员下拉
    const selectUserName = axios({
        url: '/checkData/selectUserName',
        method: 'get',
        params
    })
    return selectUserName
}
export function selectTemName(params){ // 工单管理模板下拉
    const selectTemName = axios({
        url: '/checkData/selectTemName',
        method: 'get',
        params
    })
    return selectTemName
}
export function selectAddrName(params){ // 工单管理行政区域下拉
    const selectAddrName = axios({
        url: '/checkData/selectAddrName',
        method: 'get',
        params
    })
    return selectAddrName
}
export function selectOneData(params){ // 工单管理查看详情
    const selectOneData = axios({
        url: '/checkData/selectOneData',
        method: 'get',
        params
    })
    return selectOneData
}
export function dataAudit(params){ // 审核
    const dataAudit = axios({
        url: '/checkDataAudit/dataAudit',
        method: 'get',
        params
    })
    return dataAudit
}