<template>
    <div>
        <div class="contain1">
            <div>
                任务类型
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.taskType"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="changeTaskType"
                    >
                    <el-option
                        v-for="item in taskType"
                        :key="item.id"
                        :label="item.label"
                        :value="item.id"
                    >
                    </el-option>
                </el-select>
                安检任务
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.taskId"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                    <el-option
                        v-for="item in tasks"
                        :key="item.taskId"
                        :label="item.taskName"
                        :value="item.taskId"
                    >
                    </el-option>
                </el-select>
                行政区域
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.addr"
                    class="m1150 select230"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="(item,i) in addrList"
                            :key="i"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                </el-select>
                安检员
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.userId"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="item in checkerList"
                            :key="item"
                            :label="item"
                            :value="item"
                        >
                        </el-option>
                </el-select>
                状态
                <el-select
                    filterable
                    size="mini"
                    v-model="search.statusFlag"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="item in statusList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        >
                        </el-option>
                </el-select>
                任务结果
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.checkResults"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="item in taskResultsList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        >
                        </el-option>
                </el-select>
                <br />
                安检结果
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.safetyResults"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="item in checkResultsList"
                            :key="item.id"
                            :label="item.label"
                            :value="item.id"
                        >
                        </el-option>
                </el-select>
                模板
                <el-select
                    clearable
                    filterable
                    size="mini"
                    v-model="search.tplId"
                    style="margin-left: 36px"
                    class="m1150 select130"
                    placeholder="请选择"
                    @change="inquire"
                    >
                        <el-option
                            v-for="item in templatesList"
                            :key="item.id"
                            :label="item.tplName"
                            :value="item.id"
                        >
                        </el-option>
                </el-select>
                检查时间
                <el-date-picker
                    class="m1150"
                    style="width: 230px"
                    size="mini"
                    v-model="times"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    @change="inquire">
                </el-date-picker>
                工单编号
                <el-input placeholder="请输入关键字" v-model="search.keyWord" size="mini" style="width: 400px" class="m1150">
                    <el-select placeholder="请选择" v-model="search.keyWordNumber" slot="prepend">
                        <el-option v-for="item in searchBar" :key="item.id" :label="item.label" :value="item.id"></el-option>
                    </el-select>
                </el-input>
                <el-button
                    size="mini"
                    type="primary"
                    class="ml20"
                    icon="el-icon-search"
                    @click="inquire"
                    >查询</el-button
                >
                <el-button
                    size="mini"
                    class="ml20"
                    icon="el-icon-refresh"
                    @click="refresh"
                    >重置</el-button
                >
            </div>
        </div>
        <div class="contain2">
            <div class="btn-top">
                <el-button type="primary" size="mini" icon="">导出</el-button>
            </div>
            <el-table
                :data="tableData"
                :row-style="{height: '70px'}"
                style="width: 100%;margin: 10px 0;"
                row-key="id"
                border
                default-expand-all
                :tree-props="{children: 'children', hasChildren: 'hasChildren'}"
                stripe
                :header-cell-style="{background:'#f8f8f9'}">
                <el-table-column
                    v-if="search.statusFlag==1"
                    :key="Math.random()"
                    type="selection"
                    width="55">
                </el-table-column>
                <el-table-column
                    type="index"
                    :index="indexMethod"
                    label="序号"
                    align="center"
                    width="50">
                </el-table-column>
                <el-table-column prop="dataCode" label="工单编号"></el-table-column>
                <el-table-column prop="task" label="安检任务"></el-table-column>
                <el-table-column label="任务类型" width="85">
                    <template slot-scope="{row}">
                        <span v-if="row.taskType==1">正常安检</span>
                        <span v-else-if="row.taskType==2">突击检查</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userId" label="安检员"></el-table-column>
                <el-table-column prop="userName" label="用户姓名"></el-table-column>
                <el-table-column prop="userPhone" label="联系方式"></el-table-column>
                <el-table-column prop="addr" label="行政区域"></el-table-column>
                <el-table-column label="任务结果" width="85">
                    <template slot-scope="{row}">
                        <span v-if="row.checkResults==1">正常安检</span>
                        <span v-else-if="row.checkResults==2">到访不遇</span>
                    </template>
                </el-table-column>
                <el-table-column label="安检结果" width="85">
                    <template slot-scope="{row}">
                        <span v-if="row.safetyResults==1">符合规范</span>
                        <span v-else-if="row.safetyResults==2">停气处理</span>
                        <span v-else-if="row.safetyResults==3">整改中</span>
                        <span v-else-if="row.safetyResults==4">已整改</span>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="85">
                    <template slot-scope="{row}">
                        <span style="color: #FF5400" v-if="row.statusFlag==1">未审核</span>
                        <span style="color: #67C139" v-else-if="row.statusFlag==2">已审核</span>
                        <span style="color: #FF3B3B" v-else-if="row.statusFlag==3">已驳回</span>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="检查时间" width="100"></el-table-column>
                <el-table-column prop="verifyTime" label="审核时间" width="100" v-if="search.statusFlag==2"></el-table-column>
                <el-table-column prop="createTime" label="驳回理由" width="100" v-if="search.statusFlag==3"></el-table-column>
                <el-table-column prop="createTime" label="审核人" width="100" v-if="search.statusFlag!=1"></el-table-column>
                <el-table-column label="操作" align="left" width="70">
                    <template slot-scope="{row, $index}">
                        <el-button v-if="row.statusFlag==1" type="text" size="small" @click="seeItems(row, $index)">审核</el-button>
                        <el-button v-else type="text" size="small" @click="seeItems(row, $index)">查看</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                style="text-align:center;margin: 15px 0 100px"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total"
                :page-size="search.size"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        <el-dialog
          title="工单审核"
          :visible.sync="dialogVisible"
          width="80%"
          top="5vh"
          :close-on-click-modal="false">
            <div class="btns">
                <el-button :disabled="btnShow.last" @click="anotherOne('last')">上一个</el-button>
                <el-button :disabled="btnShow.next" type="primary" @click="anotherOne('next')">下一个</el-button>
            </div>
            <el-descriptions class="margin-top" :column="4" size="small" border labelClassName="tar">
                <el-descriptions-item label="工单编号">
                    {{chosedItem.dataCode}}
                </el-descriptions-item>
                <el-descriptions-item label="完成时间">
                    {{chosedItem.verifyTime}}
                </el-descriptions-item>
                <el-descriptions-item label="安检任务">
                    {{chosedItem.task}}
                </el-descriptions-item>
                <el-descriptions-item label="任务结果">
                    {{chosedItem.checkResults == 1 ? '正常安检' : '到访不遇'}}
                </el-descriptions-item>
                <el-descriptions-item label="用户姓名">
                    {{chosedItem.userName}}
                </el-descriptions-item>
                <el-descriptions-item label="联系电话">
                    {{chosedItem.phone}}
                </el-descriptions-item>
                <el-descriptions-item :span="2" label="安检员">
                    {{chosedItem.securityName}}
                </el-descriptions-item>
                <el-descriptions-item label="行政区域">
                    {{chosedItem.addrName}}
                </el-descriptions-item>
                <el-descriptions-item label="详细地址" :span="2" content-class-name="my-content-left">
                    {{chosedItem.detailedAddress}}
                </el-descriptions-item>
                <el-descriptions-item label-class-name="my-label">
                    <el-popover
                        title="标题"
                        width="200"
                        trigger="click"
                        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                        <el-button slot="reference" type="text">打卡位置</el-button>
                    </el-popover>
                    <el-popover
                        title="标题"
                        width="200"
                        trigger="click"
                        content="这是一段内容,这是一段内容,这是一段内容,这是一段内容。">
                        <el-button slot="reference" type="text">用户签名</el-button>
                    </el-popover>
                </el-descriptions-item>
            </el-descriptions>

            <div class="card-contain">
                <el-card class="box-card" shadow="never">
                    <div slot="header" class="clearfix">
                        <span>检查项信息</span>
                        <div style="float: right; padding: 3px 0" type="text">异常项：<span style="color: #FF0A0A">sss</span></div>
                        <div style="float: right; padding: 3px 0" type="text">整改项：<span style="color: #FF0A0A">sss</span></div>
                    </div>
                    <div v-for="(item,index) in chosedItem.item" :key="item.id" class="text item">
                        <div class="level1">
                            {{ index+1 }}.{{ item.itemName }}
                            <span class="grey" v-if="item.itemNote">({{ item.itemNote }})</span>
                        </div>
                        <div v-for="ele in item.items" :key="ele.id" class="level2">
                            <template v-if="ele.checked">
                                <span class="success" v-if="ele.type == 1"></span>
                                <span class="warning" v-else-if="ele.type == 2"></span>
                                <span class="normal" v-else></span>
                                <span>{{ ele.content }}</span>
                                <div>{{ ele.note }}</div>
                                <div class="img-contain" v-if="ele.url">
                                    <el-image
                                        v-for="(img,i) in ele.url.split(',')"
                                        :key="i"
                                        :src="img"
                                        :preview-src-list="ele.url.split(',')"
                                        :z-index="10002">
                                    </el-image>
                                </div>
                                <div class="zhenggai" v-if="(chosedItem.inspectResults==3 || chosedItem.inspectResults==4) && ele.type==2">
                                    <div class="cgreen" v-if="ele.rectificationStatus==1">已整改</div>
                                    <div class="cred" v-else-if="ele.rectificationStatus==2">未整改</div>
                                    <div>{{ ele.details }}</div>
                                    <div class="img-contain" v-if="ele.picture">
                                        <el-image
                                            v-for="(img,i) in ele.picture.split(',')"
                                            :key="i"
                                            :src="img"
                                            :preview-src-list="ele.picture.split(',')"
                                            :z-index="10002">
                                        </el-image>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </el-card>
                <div>
                    <el-card class="box-card" shadow="never" :style="{height: chosedItem.inspectResults==3 || chosedItem.inspectResults==4 ? '49%' : '100%',marginBottom: '1%'}">
                        <div slot="header" class="clearfix">
                            <span>整体说明</span>
                        </div>
                        <div class="text item">
                            {{ chosedItem.overallDescription }}
                        </div>
                        <div class="img-contain" v-if="chosedItem.overallUrl">
                            <el-image
                                v-for="(item,i) in chosedItem.overallUrl.split(',')"
                                :key="i"
                                :src="item"
                                :preview-src-list="chosedItem.overallUrl.split(',')">
                            </el-image>
                        </div>
                    </el-card>
                    <el-card class="box-card" shadow="never" style="height: 49%" v-if="chosedItem.inspectResults==3 || chosedItem.inspectResults==4">
                        <div slot="header" class="clearfix">
                            <span>整改情况</span>
                        </div>
                        <div class="text item">
                            {{ chosedItem.punchData }}
                        </div>
                        <div class="img-contain" v-if="chosedItem.punchUrl">
                            <el-image
                                v-for="(item,i) in chosedItem.punchUrl.split(',')"
                                :key="i"
                                :src="item"
                                :preview-src-list="chosedItem.punchUrl.split(',')">
                            </el-image>
                        </div>
                    </el-card>
                </div>
            </div>

            <el-descriptions style="margin-top: 22px" :column="6">
                <el-descriptions-item label="检查结果">
                    <span v-if="chosedItem.inspectResults==1">符合规范</span>
                    <span v-else-if="chosedItem.inspectResults==2">停气处理</span>
                    <span v-else-if="chosedItem.inspectResults==3">已告知整改</span>
                    <span v-else-if="chosedItem.inspectResults==4">现场整改</span>
                </el-descriptions-item>
                <template v-if="chosedItem.inspectResults==3">
                    <el-descriptions-item label="整改结果">{{chosedItem.punchType == 1 ? '已整改' : '停气处理'}}</el-descriptions-item>
                    <el-descriptions-item label="整改时间">{{chosedItem.punchDate}}</el-descriptions-item>
                </template>
            </el-descriptions>

            <div slot="footer" class="dialog-footer">
                <template v-if="chosedItem.statusFlag==1">
                    <el-button type="warning" @click="innerVisible = true"> 驳 回 </el-button>
                    <el-button type="primary"> 审 核 </el-button>
                </template>
                <el-button type="primary" v-if="chosedItem.safetyResults==3" :disabled="chosedItem.dataMaintain == 2">{{chosedItem.dataMaintain == 2 ? '已提交维修' : '提交维修工单'}}</el-button>
            </div>

            <!-- 驳回原因 -->
            <el-dialog
                center
                width="30%"
                title="驳回原因"
                :close-on-click-modal="false"
                :visible.sync="innerVisible"
                @close="rejectReason = ''"
                append-to-body>
                <el-input
                    type="textarea"
                    show-word-limit
                    placeholder="请输入内容"
                    maxlength="200"
                    v-model="rejectReason">
                </el-input>
                <div slot="footer" class="dialog-footer">
                    <el-button type="danger" @click="subRejectReason(1)">确认</el-button>
                    <el-button type="primary" @click="innerVisible=false">取消</el-button>
                </div>
            </el-dialog>
        </el-dialog>
    </div>
</template>
 
<script>
import {
    selectTaskName,
    selectDataList,
    selectUserName,
    selectTemName,
    selectAddrName,
    selectOneData,
    dataAudit
    } from '../../RequestPort/civilUserOrder/order.js';
export default {
    name: 'order',
    data () {
        return {
            search: {
                taskType: '', // 任务类型 1正常安检 2突击检查
                taskId: '',	// 安检任务id
                addr: '',	// 行政区域名称(汉字！汉字！)	
                userId: '',	// 安检员名称(汉字！汉字!)
                statusFlag: 2,	// 状态1 未审核 2 审核通过 3 驳回
                checkResults: '',	// 任务结果 1正常安检 2到访不遇
                safetyResults: '',	// 安检结果 1符合规范 2停气处理 3整改中 4已整改
                tplId: '',	// 模板id
                startTime: '',	// 完成时间:开始时间
                endTime: '',	// 完成时间:结束时间
                keyWordNumber: 1,	// 1工单编号 2用户姓名 3表号 4地址 (与keyWord关键字是一体得)
                keyWord: '',	// 关键字
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            },
            findObj: {},
            taskType: [{label:'正常安检',id:1},{label:'突击检查',id:2}],
            tasks: [],
            addrList: [],
            checkerList: [],
            statusList: [{label:'未审核',id:1},{label:'已审核',id:2},{label:'已驳回',id:3}],
            taskResultsList: [{label:'正常安检',id:1},{label:'到访不遇',id:2}],
            checkResultsList: [{label:'符合规范',id:1},{label:'整改中',id:2},{label:'已整改',id:3},{label:'停气处理',id:4}],
            templatesList: [],
            times: [],
            searchBar: [{label:'工单编号',id:1},{label:'用户姓名',id:2},{label:'表号',id:3},{label:'地址',id:4}],
            tableData: [],
            total: 0,
            dialogVisible: false,
            innerVisible: false,
            chosedItem: {},
            btnShow: {
                last: true,
                next: true
            },
            rejectReason: ''
        }
    },
    mounted() {
        this.init()
        this.getCheckTask()
        this.inquire()
    },
    methods: {
        subRejectReason (type) {
            const userId = localStorage.getItem('userId')
            let param = {
                dataId: this.chosedItem.id,	// 工单id
                type: type,	// 1驳回 2审核 3提交维修工单
                userId: userId,	// 登录用户id
                rejectreason: this.rejectreason,	// 驳回原因
            }
            dataAudit(param).then(res => {
                this.getTaskList()
            })
        },
        anotherOne (type) {
            this.tableData.forEach((item,index) => {
                if (item.id == this.chosedItem.id) {
                    console.log(item.id);
                    if (type == 'last') {
                        this.seeItems(this.tableData[index-1], index-1)
                    } else if (type == 'next') {
                        this.seeItems(this.tableData[index+1], index+1)
                    }
                }
            })
        },
        seeItems (row, index) {
            console.log(index);
            index == 0 ? this.btnShow.last = true : this.btnShow.last = false
            index+1 >= this.tableData.length ? this.btnShow.next = true : this.btnShow.next = false
            this.dialogVisible = true
            selectOneData({dataId: row.id}).then(res => {
                // console.log(res.data)
                this.chosedItem = res.data
            })
        },
        changeTaskType () {
            this.getCheckTask()
            this.inquire()
        },
        refresh () {
            this.search = {
                taskType: '', // 任务类型 1正常安检 2突击检查
                taskId: '',	// 安检任务id
                addr: '',	// 行政区域名称(汉字！汉字！)	
                userId: '',	// 安检员名称(汉字！汉字!)
                statusFlag: 2,	// 状态1 未审核 2 审核通过 3 驳回
                checkResults: '',	// 任务结果 1正常安检 2到访不遇
                safetyResults: '',	// 安检结果 1符合规范 2停气处理 3整改中 4已整改
                tplId: '',	// 模板id
                startTime: '',	// 完成时间:开始时间
                endTime: '',	// 完成时间:结束时间
                keyWordNumber: 1,	// 1工单编号 2用户姓名 3表号 4地址 (与keyWord关键字是一体得)
                keyWord: '',	// 关键字
                current: 1,	// 当前页,默认值为1
                size: 100,	// 当前页条数,默认值为10
            }
            this.inquire()
        },
        inquire () {
            this.search.current = 1
            if (this.times && this.times.length > 0) {
                this.search.startTime = this.times[0]
                this.search.endTime = this.times[1]
            } else {
                this.search.startTime = ''
                this.search.endTime = ''
            }
            this.objCopy()
            this.getTaskList() 
        },
        objCopy () {
            for (let key in this.search){
                this.findObj[key] = this.search[key];
            }
        },
        getTaskList () {
            selectDataList(this.findObj).then(res => {
                const { records, total } = res.data;
                this.tableData = records;
                this.total = total;
                if (records.length === 0 && res.data.pages >= 1) {
                    this.search.current -= 1;
                    this.findObj = this.search;
                    this.getTaskList();
                }
            })
        },
        getCheckTask () {
            selectTaskName({taskType: this.search.taskType}).then(res => {
                this.tasks = res.data
            })
        },
        init () {
            selectAddrName().then((res) => {
                this.addrList = res.data;
            })
            selectUserName().then(res => {
                this.checkerList = res.data
            })
            selectTemName().then(res => {
                this.templatesList = res.data
            })
        },
        indexMethod(index) {
            return (this.search.current - 1) * this.search.size + index + 1;
        },
        handleCurrentChange (val) {
            this.search.current = val
            this.objCopy()
            this.getTaskList()
        },
        handleSizeChange (val) {
            this.search.current = 1
            this.search.size = val
            this.objCopy()
            this.getTaskList()
        },
    },
}
</script>
 
<style scoped lang="less">
 .select130 {
     width: 130px;
 }
 .btns {
     position: absolute;
     right: 80px;
     top: 15px;
 }
 ::v-deep.tar {
     text-align: right !important;
     color: #0C235B;
     font-weight: bold;
 }
 ::v-deep.el-input-group__prepend {
     width: 62px;
 }
 ::v-deep.my-label {
     background-color: #fff;
     border-left: 0 !important;
     border-right: 0 !important;
 }
 ::v-deep.my-content-left {
     border-right: 0 !important;
 }
 ::v-deep.dialog-footer {
    button {
        margin-right: 40px;
        padding: 12px 50px;
        font-size: 20px;
    }
 }
 .card-contain {
    display: flex;
    justify-content: space-between;
    margin-top: 14px;
    >div {
        width: 49%;
        min-height: 450px;
        .el-card {
            overflow: auto;
        }
    }
    ::v-deep.el-card__header {
        background-color: #F9FAFC;
        padding: 10px;
    }
 }
 .level1 {
    font-size: 16px;
    .grey {
    color: #7E8185;
    }
}
.level2 {
    text-indent: 20px;
    .bold {
    display: inline-block;
    width: 5px;
    height: 5px;
    margin-left: 4px;
    border-radius: 50%;
    vertical-align: middle;
    margin-right: 5px;
    background-color: rgb(19, 152, 230);
    }
    .warning, .success, .normal {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    margin-right: 4px;
    vertical-align: sub;
    }
    .warning {
    background-image: url('../../assets/warning.png');
    }
    .success {
    background-image: url('../../assets/normal.png');
    }
}
.cgreen {
    color: rgb(21, 255, 0);
}
.cred {
    color: rgb(255, 7, 7);
}
.img-contain {
    text-indent: initial;
}
.el-image {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
#container {
    width: 100%;
    height: 300px;
}
</style>